/* General Cart Styles */
.cart {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 900px;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .cart h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cart p {
    text-align: center;
    color: #7f8c8d;
  }
  
  .cart ul {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #ecf0f1;
  }
  
  .cart-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .item-details {
    flex: 1;
    margin-left: 15px;
  }
  
  .item-details h3 {
    font-size: 20px;
    margin: 0;
  }
  
  .item-details p {
    font-size: 14px;
    color: #7f8c8d;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .quantity-controls button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .quantity-controls button:hover {
    background-color: #27ae60;
  }
  
  .remove-item {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .remove-item:hover {
    background-color: #c0392b;
  }
  
  .cart-summary {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ecf0f1;
  }
  
  .cart-summary p {
    font-size: 20px;
    font-weight: bold;
  }
  
  .checkout-btn {
    background-color: #2980b9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .checkout-btn:hover {
    background-color: #3498db;
  }
  