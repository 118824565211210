/* Navbar Container */
.navbar {
    background-color: #2c3e50;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #ecf0f1;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    background-color: #34495e;
    padding: 5px;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-input {
    border: none;
    background-color: transparent;
    color: white;
    padding: 8px;
    border-radius: 30px;
    width: 200px;
    font-size: 14px;
  }
  
  .search-btn {
    background-color: #3498db;
    border: none;
    padding: 8px 12px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
    transition: background-color 0.3s ease;
  }
  
  .search-btn:hover {
    background-color: #2980b9;
  }
  
  .cart-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  
  .cart-icon {
    font-size: 24px;
    color: white;
  }
  
  .cart-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #e74c3c;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 50%;
  }
  
  .cart-popup {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #e74c3c;
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Media Query for Responsiveness */
  @media (max-width: 768px) {
    .navbar-container {
      flex-direction: column;
      align-items: center;
    }
  
    .search-container {
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
  
    .search-input {
      width: 250px;
    }
  
    .cart-container {
      margin-top: 10px;
    }
  }
  