/* Shop Container */
.shop {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    justify-items: center;
  }
  
  /* Product Card */
  .product-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }
  
  .product-image:hover {
    opacity: 0.8;
  }
  
  .product-card h3 {
    font-size: 18px;
    margin: 15px;
    text-align: center;
  }
  
  .product-card p {
    font-size: 16px;
    color: #7f8c8d;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .add-to-cart-btn {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-btn:hover {
    background-color: #27ae60;
  }
  